import React from 'react'
import ChangeLang from './components/ChangeLang'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const Contact = (props) => {
    const form = useRef();

    const sendEmail = (e)=>{
        e.preventDefault();
    
        emailjs.sendForm('service_gepf5nr','template_60ol3kb',form.current,'L9GsshwS_B-Mhy9px').then((result)=>{
            console.log(result.text)
        },(error)=>{
            console.log(error.text)
        })
    
        document.getElementById('name').value = "";
        document.getElementById('email').value = "";
        document.getElementById('subject').value = "";
        document.getElementById('phone').value = "";
        document.getElementById('message').value = "";
    
    }
  return (
    <div>
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
        <Link to="/" className="navbar-brand d-flex align-items-center">
             <img src="img/logo.png" alt="" style={{width:'120px'}} /><h6 style={{color:'#FDA534'}}>BEACONRY / NGO</h6> 
        </Link>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto py-3 py-lg-0">
                  <Link to="/" className="nav-item nav-link ">{props.t('home')}</Link>
                  <Link to="/about" className="nav-item nav-link">{props.t('aboutUs')}</Link>
                  <Link to="/stories" className="nav-item nav-link">{props.t('stories')}</Link>
                  <Link to="/elderly_home" className="nav-item nav-link ">{props.t('elderly_home')}</Link>
                  <Link to="/contact" className="nav-item nav-link active">{props.t('contact')}</Link>
                  <div className="nav-item nav-link">
                    <ChangeLang t={props.t} />
                  </div>
                  <Link to="/donate">
                      <button style={{backgroundColor: 'black', color: 'white', borderRadius: '25px', marginTop: '15px', marginLeft: "22px", padding: "5px 13px"}}>{props.t('Donate')}</button>
                  </Link> 
                 
                  
              </div>
          </div>
    </nav>
    <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center py-5">
            <h1 className="display-4 text-white animated slideInDown mb-4">{props.t('contactUs')}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a className="text-white" href="#">{props.t('home')}</a></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">{props.t('contactUs')}</li>
                </ol>
            </nav>
        </div>
    </div>
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight: '450px'}}>
                    <div className="position-relative h-100">
                        <iframe className="position-relative w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1881.5032949985498!2d28.83872737721684!3d61.86334127593921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469a5aaeca715c4f%3A0x60b70ba3704f1818!2sSiikakuja%203%2C%2057600%20Savonlinna%2C%20Finland!5e0!3m2!1sen!2snl!4v1688374426519!5m2!1sen!2snl" 
                        frameborder="0" style={{minHeight: '450px', border:'0'}} allowfullscreen="" aria-hidden="false"
                        tabindex="0"></iframe>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="border-start border-5 border-primary ps-4 mb-5">
                        <h6 className="text-body text-uppercase mb-2">{props.t('contactUs')}</h6>
                        <h1 className="display-6 mb-0">{props.t('question')}</h1>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control border-0 bg-light" id="name" name='user_name' placeholder= {props.t('yourName')}/>
                                    <label for="name">{props.t('yourName')}</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="email" className="form-control border-0 bg-light" id="email" name='user_email' placeholder={props.t('email')}/>
                                    <label for="email">{props.t('email')}</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control border-0 bg-light" id="phone" name='phoneNumber' placeholder={props.t('mobile')}/>
                                    <label for="cname">{props.t('mobile')}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control border-0 bg-light" id="subject" name='subject' placeholder={props.t('subject')}/>
                                    <label for="subject">{props.t('subject')}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <textarea className="form-control border-0 bg-light" placeholder={props.t('message')} name='message' id="message" style={{height: '150px'}}></textarea>
                                    <label for="message">{props.t('message')}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary py-3 px-5" type="submit">{props.t('send')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-4 col-md-6">
                <img src="img/logo_white.png" alt="" style={{width:'130px', marginBottom:'20px'}}/>
                <p>{props.t('join')}</p>
                <div className="d-flex pt-2">
                    <a className="btn btn-square btn-outline-primary me-1" href=""><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square btn-outline-primary me-1" href="https://www.facebook.com/profile.php?id=100089544854897" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square btn-outline-primary me-1" href=""><i className="fab fa-youtube"></i></a>
                    <a className="btn btn-square btn-outline-primary me-0" href=""><i className="fab fa-linkedin-in"></i></a>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <h4 className="text-light mb-4">{props.t('address')}</h4>
                <p><i className="fa fa-map-marker-alt me-3"></i>SIIKAKUJA 3 57600, SAVONLINNA, FINLAND</p>
                <p><i className="fa fa-phone-alt me-3"></i>+358 (0) 409527868</p>
                <p><i className="fa fa-envelope me-3"></i>info@beaconry.org</p>
            </div>
            <div className="col-lg-4 col-md-6">
                <h4 className="text-light mb-4">{props.t('news_letter')}</h4>
                <p>{props.t('workWithUs')}</p>
                <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
                    <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder={props.t('email')}/>
                    <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">{props.t('signUp')}</button>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid copyright">
        <div className="container">
            <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    &copy; <a href="#">Beaconry</a>, {props.t('arr')}.
                </div>
                <div className="col-md-6 text-center text-md-end">
                    
                {props.t('designed_by')} <a href="https://earlystarttech.com" target='_blank'>Early Start IT Solution</a>
                </div>
            </div>
        </div>
    </div>
</div>
<a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>


    </div>
  )
}

export default Contact