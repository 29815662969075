import React from "react";
import "./styles.css";
import { Translation } from "react-i18next";
import ChangeLang from "./components/ChangeLang";
import { Route, Routes } from "react-router-dom";
import About from './About'
import Stories from "./Stories";
import Contact from './Contact'
import Home from './Home'
import ElderlyHome from "./ElderlyHome";
import Donate from "./Donate";
export default function App() {
  return (
    <div className="App">
         
    <Routes>
      <Route path='/' element={<Translation>{t=> <Home t={t}/>}</Translation> } />
      <Route path='/about' element={<Translation>{t=> <About t={t}/>}</Translation>} />
      <Route path='/stories' element={<Translation>{t=> <Stories t={t}/>}</Translation>} />
      <Route path='/elderly_home' element={<Translation>{t=> <ElderlyHome t={t}/>}</Translation>} />
      <Route path='/contact' element={<Translation>{t=> <Contact t={t}/>}</Translation>} />
      <Route path='/donate' element={<Translation>{t=> <Donate t={t}/>}</Translation>} />
    </Routes>
   
    </div>
  );
}
// <Translation>{t => <ChangeLang t={t} />}</Translation>