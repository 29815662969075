import React from 'react'
import ChangeLang from './components/ChangeLang'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const Donate = (props) => {
    
  return (
    <div>
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
            <Link to="/" className="navbar-brand d-flex align-items-center">
                <img src="img/logo.png" alt="" style={{width:'120px'}} /><h6 style={{color:'#FDA534'}}>BEACONRY / NGO</h6>
            </Link>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-3 py-lg-0">
                    <Link to="/" className="nav-item nav-link ">{props.t('home')}</Link>
                    <Link to="/about" className="nav-item nav-link">{props.t('aboutUs')}</Link>
                    <Link to="/stories" className="nav-item nav-link">{props.t('stories')}</Link>
                    <Link to="/elderly_home" className="nav-item nav-link ">{props.t('elderly_home')}</Link>
                    <Link to="/contact" className="nav-item nav-link active">{props.t('contact')}</Link>
                    <div className="nav-item nav-link">
                        <ChangeLang t={props.t} />
                    </div>
                    <Link to="/donate">
                        <button style={{backgroundColor: 'black', color: 'white', borderRadius: '25px', marginTop: '15px', marginLeft: "22px", padding: "5px 13px"}}>{props.t('Donate')}</button>
                    </Link> 
                    
                    
                </div>
            </div>
        </nav>
        <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container text-center py-5">
                <h1 className="display-4 text-white animated slideInDown mb-4">{props.t('Donate')}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb justify-content-center mb-0">
                        <li className="breadcrumb-item"><a className="text-white" href="#">{props.t('home')}</a></li>
                        <li className="breadcrumb-item text-primary active" aria-current="page">{props.t('Donate')}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" >
                        <div className="position-relative ">
                            <div>
                                <p>Recipient/ Owner of the bank account: BEACONRY
                                <br/> Bank account number: FI74 5271 0420 5586 04
                                <br/> BIC/SWIFT code: OKOYFIHH 
                                <br/> VAT ID :FI33362059
                                <br/> Association address
                                <br/> BEACON RY /NGO
                                <br/> SIIKAKUJA 3,
                                <br/> 57600   Savonlinna
                                <br/> Finland
                                <br/> +358 (0) 409 527 868
                                <br/> www.beaconry.org</p>
                            </div>
                        </div>    
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" >
                            <div className="position-relative ">   
                                <div style={{marginTop:'50px'}}>
                                
                                    <p>BANK NAME: - SUUR-SAVON OSUUSPANKKI 
                                    <br/>STREET ADDRESS: -PORRASSALMENKATU 19
                                        50100 MIKKLE 
                                        MIKKELE, FINLAND</p>
                                
                                </div>
                            </div> 

                        </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="border-start border-5 border-primary ps-4 mb-5">
                        <h6 className="text-body text-uppercase mb-2">{props.t('th_donate')}</h6>
                        <h5 className="display-10 mb-0">{props.t('donate_d')}</h5>
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <img src="img/logo_white.png" alt="" style={{width:'130px', marginBottom:'20px'}}/>
                        <p>{props.t('join')}</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-square btn-outline-primary me-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square btn-outline-primary me-1" href="https://www.facebook.com/profile.php?id=100089544854897" target="_blank"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square btn-outline-primary me-1" href=""><i className="fab fa-youtube"></i></a>
                            <a className="btn btn-square btn-outline-primary me-0" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-light mb-4">{props.t('address')}</h4>
                        <p><i className="fa fa-map-marker-alt me-3"></i>SIIKAKUJA 3 57600, SAVONLINNA, FINLAND</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+358 (0) 409527868</p>
                        <p><i className="fa fa-envelope me-3"></i>info@beaconry.org</p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-light mb-4">{props.t('news_letter')}</h4>
                        <p>{props.t('workWithUs')}</p>
                        <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
                            <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder={props.t('email')}/>
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">{props.t('signUp')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a href="#">Beaconry</a>, {props.t('arr')}.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            
                        {props.t('designed_by')} <a href="https://earlystarttech.com" target='_blank'>Early Start IT Solution</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </div>
  )
}

export default Donate