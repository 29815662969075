import React from "react";
import ChangeLang from "./components/ChangeLang";
import emailjs from '@emailjs/browser';
import { useRef } from "react";
import { Link } from "react-router-dom";

function Home(props) {
        const form = useRef();
    
        const sendEmail = (e)=>{
            e.preventDefault();
        
            emailjs.sendForm('service_gepf5nr','template_60ol3kb',form.current,'L9GsshwS_B-Mhy9px').then((result)=>{
                console.log(result.text)
            },(error)=>{
                console.log(error.text)
            })
        
            document.getElementById('name').value = "";
            document.getElementById('email').value = "";
            document.getElementById('subject').value = "";
            document.getElementById('phone').value = "";
            document.getElementById('message').value = "";
        
        }
  return (
    <div className="Home">
     
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
          <Link to="/" className="navbar-brand d-flex align-items-center">
              <img src="img/logo.png" alt="" style={{width:'120px'}} /> <h6 style={{color:'#FDA534'}}>BEACONRY / NGO</h6>
              
          </Link>
          
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto py-3 py-lg-0">
                  <Link to="/" className="nav-item nav-link active">{props.t('home')}</Link>
                  <Link to="/about" className="nav-item nav-link">{props.t('aboutUs')}</Link>
                  <Link to="/stories" className="nav-item nav-link">{props.t('stories')}</Link>
                  <Link to="/elderly_home" className="nav-item nav-link ">{props.t('elderly_home')}</Link>
                  <Link to="/contact" className="nav-item nav-link">{props.t('contact')}</Link>
                  <div className="nav-item nav-link">
                    <ChangeLang t={props.t} />
                  </div>
                  <Link to="/donate">
                      <button style={{backgroundColor: 'black', color: 'white', borderRadius: '25px', marginTop: '15px', marginLeft: "22px", padding: "5px 13px"}}>{props.t('Donate')}</button>
                  </Link> 
                 
                  
              </div>
          </div>
      </nav>
   
    <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100 h-50" src="img/gallery/image13.jpg" alt="Image"/>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    
                                    <h5 className="text-light  mb-3 animated slideInDown">{props.t('Hi')}</h5>
                                    <h3 className="display-4 text-light mb-3 animated slideInDown">{props.t('Actions')} </h3>
                                    <h4 className="text-light  mb-3 animated slideInDown">{props.t('wellcoming')}</h4>
                                    <Link to="/donate" className="btn btn-primary py-3 px-5">{props.t('Donate')}</Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100 h-50"  src="img/gallery/image21.jpg" alt="Image"/>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <h5 className="text-light  mb-3 animated slideInDown">{props.t('Hi')}</h5>
                                    <h3 className="display-4 text-light mb-3 animated slideInDown">{props.t('Education')}</h3>
                                    <h4 className="text-light mb-3 animated slideInDown">{props.t('Together')}</h4>
                                
                                    <Link to="/donate" className="btn btn-primary py-3 px-5">{props.t('Donate')}</Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{minHeight: '400px'}}>
                        <img className="position-absolute w-100 h-100" src="img/gallery/image18.jpg" alt="" style={{objectFit: 'cover'}}/>
                        <div className="position-absolute top-0 start-0 bg-white pe-3 pb-3" style={{width: '200px', height: '200px'}}>
                            <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                                <h2 className="text-white">{props.t('time1')}</h2>
                                <h3 className="text-white">{props.t('time_no')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                        <div className="border-start border-5 border-primary ps-4 mb-5">
                            <h6 className="text-body text-uppercase mb-2">A{props.t('aboutAbove')}</h6>
                            <h1 className="display-6 mb-0">{props.t('Hi')}</h1>
                        </div>
                        <p>{props.t('About')}</p>
                        <p>{props.t('About2')}</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-fluid my-5 p-lg-5 wow fadeIn" data-wow-delay="0.1s" style={{height: '100%', backgroundColor: '#fda534'}}>
        <div className="row g-2">
            <div className="col-xl-12">
                <center className="">
                    <h1 className="display-5 mb-0" style={{color: 'white'}}>{props.t('wwd')}</h1>
                </center>
            </div>
            <div className="col-xl-4 col-sm-6 wow fadeIn g-5" data-wow-delay="0.1s">
                <div className="position-relative">
                    
                    <div >
                        <h6 className="display-6" style={{color: 'white'}}>{props.t('bs')}</h6>
                        <p className="text-white">
                        {props.t('bs_d')}
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6 wow fadeIn g-5" data-wow-delay="0.1s">
                <div className="position-relative">
                    
                    <div >
                        <h6 className="display-6" style={{color: 'white'}}>{props.t('es')}</h6>
                        <p className="text-white">
                            {props.t('es_d')}
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6 wow fadeIn g-5" data-wow-delay="0.1s">
                <div className="position-relative">
                    
                    <div >
                        <h6 className="display-6" style={{color: 'white'}}>{props.t('ce')}</h6>
                        <p className="text-white">
                        {props.t('ce_d')}
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6 wow fadeIn g-5" data-wow-delay="0.1s">
                <div className="position-relative">
                    
                    <div >
                        <h6 className="display-6" style={{color: 'white'}}> {props.t('sd')}</h6>
                        <p className="text-white">
                        {props.t('sdd')}
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6 wow fadeIn g-5" data-wow-delay="0.1s">
                <div className="position-relative">
                    
                    <div >
                        <h6 className="display-6" style={{color: 'white'}}> {props.t('els')}</h6>
                        <p className="text-white">
                        {props.t('elsd')}
                        </p>
                        
                    </div>
                </div>
            </div>
            
            
        </div>
    </div>
  
    <div className="container-xxl py-5">

    <div className="container">
        <div className="border-start border-5 border-primary ps-4 mb-5">
            <h6 className="text-body text-uppercase mb-2">{props.t('dtos')}</h6>
            <h1 className="display-6 mb-0">{props.t('stories')}</h1>
        </div>
        <div className="row g-5">
            <div className="col-lg-6 wow " data-wow-delay="0.1s" style={{marginTop:'130px'}}>
                <h6>Kefle</h6>
                <p className="mt-5" style={{marginTop: "135px !important"}}>
                {props.t('kefle')}
                </p>
                
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item position-relative">
                    <img className="img-fluid" style={{width: "400px"}}  src="img/stories/kefle.png" alt=""/>
                    <div className="team-text bg-white p-4">
                        <h5>Kefle</h5>
                        <span>{props.t('st')}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item position-relative">
                    <img className="img-fluid" style={{width: '400px'}}  src="img/stories/beru.png" alt=""/>
                    <div className="team-text bg-white p-4">
                        <h5>Beru Getachew</h5>
                        <span>{props.t('Below')}</span>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" style={{marginTop:'130px'}} data-wow-delay="0.1s">
               <h6>Beru Getachew</h6>
                <p className="mt-5" style={{marginTop: "135px !important"}}>
                {props.t('Beru')}
                </p>
                
            </div>
        </div>
        <div className="row g-5">
            <div className="col-lg-6 col-md-6 wow fadeInUp" style={{marginTop:'130px'}} data-wow-delay="0.1s">
                <h6>Guday {props.t('and')}Tigist</h6>
                <p className="mt-5" style={{marginTop: "135px !important"}}>
                {props.t('Gudaye')}
                
                </p>
            </div>
            <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item position-relative">
                    <img className="img-fluid" style={{width: '400px'}}  src="img/stories/gudaye.png" alt=""/>
                    <div className="team-text bg-white p-4">
                        <h5>Guday Beyene</h5>
                        <span>{props.t('Teacher')}</span>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item position-relative">
                    <img className="img-fluid" style={{width: '400px'}}  src="img/stories/tigist.png" alt=""/>
                    <div className="team-text bg-white p-4">
                        <h5>Tigist Bekele</h5>
                        <span>{props.t('Teacher')}</span>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item position-relative">
                    <img className="img-fluid" style={{width: '400px'}}  src="img/stories/hailu.jpg" alt=""/>
                    <div className="team-text bg-white p-4">
                        <h5> Hailu</h5>
                        <span>{props.t('admin')}</span>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 wow " data-wow-delay="0.1s" style={{marginTop:'130px'}}>
               <h6>Hailu</h6>
                <p className="mt-5" style={{marginTop: "135px !important"}}>
                 {props.t('paragraphFi')}
                </p>
                
            </div>
        </div>
    </div>
    </div>
  
    <div className=" py-5">
        <div className="container">
            <div className="row g-5 align-items-end mb-5">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="border-start border-5 border-primary ps-4">
                        <h6 className="text-body text-uppercase mb-2"></h6>
                        <h1 className="display-6 mb-0">{props.t('gallery')}</h1>
                    </div>
                </div>
            </div>
            <div className="row g-5 align-items-end mb-5">    
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image1.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image2.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image3.png" alt=""/>
                    </div>
                </div>
                
            </div>
            <div className="row g-5 align-items-end mb-5">    
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image4.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image8.jpg" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image6.png" alt=""/>
                    </div>
                </div>
                
            </div>
            <div className="row g-5 align-items-end mb-5">    
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image5.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image12.jpg" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image9.jpg" alt=""/>
                    </div>
                </div>
                
            </div>
            <div className="row g-5 align-items-end mb-5">    
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image10.jpg" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image7.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light overflow-hidden h-100">
                        <img className="img-fluid" src="img/gallery/image7.jpg" alt=""/>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
    <div className="container-fluid appointment my-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay="0.3s">
                    <div className="border-start border-5 border-primary ps-4 mb-5">
                        <h6 className="text-white text-uppercase mb-2">{props.t('contact')}</h6>
                        <h1 className="display-6 text-white mb-0">{props.t('workWithUs')}</h1>
                    </div>
                    <p className="text-white mb-0">{props.t('join')}</p>
                </div>
                <div className="col-lg-7 col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="row g-3">
                            <div className="col-sm-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control bg-dark border-0" id="name" name='user_name' placeholder={props.t('yourName')}/>
                                    <label for="gname">{props.t('yourName')}</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                    <input type="email" className="form-control bg-dark border-0" id="email" name='user_email' placeholder={props.t('email')}/>
                                    <label for="gmail">{props.t('email')}</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control bg-dark border-0" id="phone" name='phoneNumber' placeholder={props.t('mobile')}/>
                                    <label for="cname">{props.t('mobile')}</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control bg-dark border-0" id="subject" name='subject' placeholder={props.t('subject')}/>
                                    <label for="cname">{props.t('subject')}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <textarea className="form-control bg-dark border-0" placeholder="Leave a message here" id="message" name='message' style={{height: '100px'}}></textarea>
                                    <label for="message">{props.t('message')}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary w-100 py-3" type="submit">{props.t('send')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-xxl py-5 ">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="border-start border-5 border-primary ps-4 mb-5">
                        <h6 className="text-body text-uppercase mb-2">{props.t('testimonial')}</h6>
                        <h1 className="display-6 mb-0">{props.t('message')}</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-sm-6">
                            <div className="d-flex align-items-center mb-2">
                                <i className="fa fa-users fa-2x text-primary flex-shrink-0"></i>
                                <h1 className="ms-3 mb-0">123+</h1>
                            </div>
                            <h5 className="mb-0">{props.t('individual')}</h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex align-items-center mb-2">
                                <i className="fa fa-check fa-2x text-primary flex-shrink-0"></i>
                                <h1 className="ms-3 mb-0">123+</h1>
                            </div>
                            <h5 className="mb-0">{props.t('endProject')}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.1s" >
                    <div className="testimonial-carousel">
                        <div className="testimonial-item">
                            <img className="img-fluid mb-4" src="img/stories/hailu.jpg" alt="" style={{width:'100px'}}/>
                            <h5>Hailu</h5>
                            <div className="bg-primary mb-3" style={{width:' 60px', height: "5px"}}></div>
                            <p className="fs-5" style={{color:'black'}}>{props.t('test_1')}</p>
                        </div>
                        <div className="testimonial-item " style={{marginTop:'50px'}}>
                            <img className="img-fluid mb-4" src="img/stories/lady_test.jpg" alt=""  style={{width:'100px'}}/>
                            <h5>Mirka Pitkonen</h5>
                            <div className="bg-primary mb-3" style={{width: '60px', height: "5px"}}></div>
                            <p className="fs-5"  style={{color:'black'}}>{props.t('test_2')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <img src="img/logo_white.png" alt="" style={{width:'130px', marginBottom:'20px'}}/>
                    <p>{props.t('join')}</p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-square btn-outline-primary me-1" href=""><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-square btn-outline-primary me-1" href="https://www.facebook.com/profile.php?id=100089544854897" target="_blank"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-square btn-outline-primary me-1" href=""><i className="fab fa-youtube"></i></a>
                        <a className="btn btn-square btn-outline-primary me-0" href=""><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">{props.t('address')}</h4>
                    <p><i className="fa fa-map-marker-alt me-3"></i>SIIKAKUJA 3 57600, SAVONLINNA, FINLAND</p>
                    <p><i className="fa fa-phone-alt me-3"></i>+358 (0) 409527868</p>
                    <p><i className="fa fa-envelope me-3"></i>info@beaconry.org</p>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">{props.t('news_letter')}</h4>
                    <p>{props.t('workWithUs')}</p>
                    <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
                        <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder={props.t('email')}/>
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">{props.t('signUp')}</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a href="#">Beaconry</a>, {props.t('arr')}.
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        
                    {props.t('designed_by')} <a href="https://earlystarttech.com" target='_blank'>Early Start IT Solution</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>

    </div>
  );
  }
export default Home;


// Spinner Start 
// <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
//     <div className="spinner-grow text-primary" role="status"></div>
// </div>



// <div className="container-fluid bg-light p-0">
// <div className="row gx-0 d-none d-lg-flex">
//     <div className="col-lg-7 px-5 text-start">
//         <div className="h-100 d-inline-flex align-items-center border-start border-end px-3">
//             <small className="fa fa-phone-alt me-2"></small>
//             <small>+012 345 6789</small>
//         </div>
//         <div className="h-100 d-inline-flex align-items-center border-end px-3">
//             <small className="far fa-envelope-open me-2"></small>
//             <small>info@beaconry.org</small>
//         </div>
//     </div>
//     <div className="col-lg-5 px-5 text-end">
//         <div className="h-100 d-inline-flex align-items-center">
//             <a className="btn btn-square border-end border-start" href=""><i className="fab fa-facebook-f"></i></a>
//             <a className="btn btn-square border-end" href=""><i className="fab fa-twitter"></i></a>
//             <a className="btn btn-square border-end" href=""><i className="fab fa-linkedin-in"></i></a>
//             <a className="btn btn-square border-end" href=""><i className="fab fa-instagram"></i></a>
//         </div>
//     </div>
// </div>
// </div> 

// <p className="mb-4">Since our establishment in 2003 G.C, we have been actively working towards bridging the educational gap in Ethiopia. Our team of passionate individuals believes that education is the key to breaking the cycle of poverty and unlocking the true potential of every child. We are driven by the belief that every child deserves access to quality education, regardless of their socio-economic background.