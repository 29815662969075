import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  languages:['en'],
  lng: 'en',
  debug: false,
  fallbackLng: 'en',
  returnObjectTrees: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      translation: {
        Hi: "Welcome to Beaconry",
        Actions: "Investing in education is an investment in humanity's greatest potential.",
        wellcoming: "Join our charity as we build schools, inspire minds, and transform lives.",
        Education: "Education is the key that unlocks the doors of opportunity.",
        Together: "Together, let's build schools and empower students to shape a brighter future.",
        Donate: "Donate",
        time1:"Since",
        time_no: "2005 G.C ",
        aboutAbove: "ABOUT US",
        About: " Welcome to Beacory, a dedicated non-profit organization committed to building schools and providing education worldwide. Our mission is to empower underprivileged communities by creating sustainable educational opportunities, nurturing young minds, supporting elderly, and fostering positive change.",
        About2:'Since our establishment in 2005 G.C, we have been actively working towards bridging the educational gap in Ethiopia. Our team of passionate individuals believes that education is the key to breaking the cycle of poverty and unlocking the true potential of every child. We are driven by the belief that every child deserves access to quality education, regardless of their socio-economic background.',
        wwd:'What we do',
        bs:'Building Schools',
        bs_d:'We recognize the critical role of infrastructure in promoting education. We collaborate with local communities to construct schools in areas where educational resources are scarce.',
        es:'Educational Support',
        es_d:'We believe that education goes beyond the classroom. Our organization is dedicated to providing comprehensive support to students, ensuring they have the resources they need to thrive academically.  ',
        ce:'Community Engagement',
        ce_d:'We strongly believe in the power of community involvement and collaboration. We work closely with local communities, parents, and stakeholders to understand their needs and aspirations. ',
        sd:'Sustainable Development',
        sdd:'Beaconry is committed to long-term, sustainable solutions. We emphasize the importance of environmental consciousness and promote eco-friendly practices in school construction and operations. ',
        els:'Elderly Support',
        elsd:'Our charity is dedicated to providing comprehensive support and care for the elderly in our community. We believe that every senior citizen deserves to live a fulfilling and dignified life in their golden years. Through various programs and services, we strive to address their physical, emotional, and social needs.',
       
        dtos:'Donate to Our School',
       
        st:'Student',
        
        kefle: "Kefle is a young man who need better education. Now working as a gard at the LightHouse school to support himself and his family. He can and deserve better education and better future.",
        Beru: "Beru Getachew is a young man who has a university education, a master’s degree in teaching. He works at the Lighthouse School as a school director on a small salary. He is our future teacher in our in-service teachers training at the BEACONRY TEACHERS COLLEGE. Donate for the establishment of the new college and mark your name with the BEACONRY TEACHER’ COLLEGE. Donate here Thank you! ",
        Gudaye: "Gudaye and Tigist are kindergarten teachers who are working on a small salary just to support the school and the community. They need farther training, and they are recruited to be the first students at the BEACONRY TEACHERS’ COLLEGE. Donate for future (the teachers training and salary). ",
        name: "Guday Beyene  ",
        Teacher: "Kindergarten Teacher",
        Below: "Teacher",
        second: "Hailu",
        paragraphFi: "Hailu is administrator who needs training about school leadership, Computer skills and language studies. Providing training for adult workers and trainees at the BEACONRY SCHOOL PROJECTS supports the greater community. People like Hailu know the know-how about the culture, the people, and the community but they need more training, especially computer skills. When you donate for our training projects you will improve the skills of our workers furthermore, you will support their families and extended families.",
        titleProject: "The BEACONRY LIBRARY PROJECT ",
        project: "The library project is part of the school project. Please donate to our library project in the school compound. Your kind donation will support the generation to come and the community. Thank you for your support.",
        gallery: "Gallery",
        contact: "CONTACT US ",
        workWithUs: "Work with us, together we can make a difference.",
        join: "Join us in building a brighter future, one life at a time. Together, we can make a lasting impact. Empowering communities and transforming lives. Your support can create a world of opportunities. ",
        testimonial: "TESTIMONIAL ",
        messages: "Messages from our Donors",
        num1: "+358",
        individual: "Individuals Helped",
        num2: "+358",
        endProject: "Projects Done",
        founder:"FOUNDER AND PRESIDENT",
        rahel: "Rahel Tariku",
        do_name:'Donater Name',
        paragraph1: " Rahel Tariku was born and raised in Ethiopia. She is naturally gifted teacher with a Diploma in teaching from Alpha University College Addis Ababa; ETHIOPIA and Master’s Degree in Educational Sciences from the UNIVERSITY OF JYVÄSKYLÄ Finland. Her dedication and love for supporting young children around the world began in 2005 by creating Lighthouse School. Over 17 years of experience in practical teaching, creating a school, and understanding the school organizations in Ethiopia, Finland and elsewhere enriched her understanding of the schools that are sustainable and inclusive.",
        paragraph2: "Creating a school requires your heart not only knowledge. Living in Finland where there are four seasons and relaxed lifestyle, her vision and mission is still thinking about the children who need school, food, and shelter. The elderly who needs holistic services. The large families who need education, better standard of living. The library that gives life to the community. Now she is equipped with a better education and more knowledge. Support her to support her vision and mission.",
        paragraph3: "Order her training skills, teachers' training of any kind at any level (the name of the course is a teacher’s bag), classroom pedagogy, principal-ship training, school administration, teacher student relationship, short- and long-term training or summer school training. Online or in person teachings are waiting for you. By ordering her expertise you will help her vision to help the now and the future projects around the world. Beaconry works for people with people.",
        beaconrytitle: "Beaconry senior home project",
        beaconryProject: " A year ago, my mother Elizabeth Abebe called me about her difficulties walking and the kind of support she needed. I was devastated and very sad to hear that she couldn’t walk or move actively as she used to and that I had limitations to be able to support her. I decided to find a long-lasting solution for all moms. Instead of only helping my mom I envisioned BEACONRY ELIZABETH HOME ETHIOPIA to be established because of my mom and all moms who mostly know to work hard and to give.",
        beaconryProject_2: "Elisabeth gave a lot to the community and her family. My mother is a role model and a mother for many young people. Many mothers are tired which is related to their age, lack of balanced diet food, sport hall activities in their area, lack of trainers, war, poverty, misunderstanding of age-related diseases and lack of pre-preparation of elderly age. This way of life can be devastating and very sad.",
        beaconryProject_3: "Many elderlies are suffering with similar situations. LET US work TOGETHER for the establishment of BEACONRY ELIZABETH HOME ETHIOPIA. By doing so, you save my mom, and you save many moms in Ethiopia. The BEACONRY ELISABETH HOME ETHIOPIA will be established where the nature and the weather condition is favorable. The kindergarten, the open market, the farm, the sport center, hobby rooms can be easily accessed around them. The elderly who gathers there can generate income for farther development of the BEACONRY project. How? Connect with us! Donate for my mother, other mothers and elderly’s lives today. Thank you!",
        beaconryProject_4: "BEACONRY ELSABETH HOME ETHIOPIA will be established with your donations, wills and testaments. Your cash kind support is welcome. We need facilities that are easy for elderlies and children. Doctors, health workers, sport trainers, experts in challenges with elderly people are expected to share their knowledge in the beautiful suburban and countryside area of Ethiopia.",
        question: " If You Have Any Query, Please Contact Us",
        yourName: "Your Name",
        email: "Your Email",
        mobile: "Your Mobile",
        subject: "Subject",
        message: "Message",
        home: "Home",
        aboutUs: "About Us",
        stories: "Stories",
        contactUs: "Contact Us",
        donate2: "Donate",
        address:'Address',
        news_letter:'News Letter',
       signUp:'Sign Up',
       send:'Send',
       designed_by:'Designed By',
        arr:'All Right Reserved',
        elderly_home:"Elderly Home",
        charity_1:'Our charity is dedicated to providing comprehensive support and care for the elderly in our community. We believe that every senior citizen deserves to live a fulfilling and dignified life in their golden years. Through various programs and services, we strive to address their physical, emotional, and social needs. Our elderly support initiatives encompass a wide range of activities, including healthcare assistance, home visits, and companionship programs to combat isolation. We also organize recreational events, workshops, and educational sessions to keep their minds engaged and active. Additionally, we collaborate with healthcare professionals and volunteers to ensure regular health check-ups, medication management, and specialized care for those with specific medical conditions.',
        charity_2:'At our charity, we emphasize the importance of fostering a sense of belonging and purpose within our elderly community. Through our efforts, we aim to create an inclusive and caring environment where they can find joy, support, and the comfort of knowing they are cherished and respected. Together, we strive to make their later years truly golden.',
        language:'Language',
        test_1:'I am very pleased to say that my experience of associating with BEACONRY Organization has been excellent and I feel it is a privilege too. My appreciation for the excellent work BEACONRY is carrying out in uplifting under- privileged children. By providing opportunities to needy children, BEACONRY is investing in a better future for the World, since children are the future.',
        test_2:'It gives me immense pleasure to share my thoughts about BEACONRY Organization. The idea to have a realistic NGO strike to me when I came across with underprivileged children who were deprived of basic needs like education, health care and nutrition.',
        admin:'Adminstrator',
        th_donate:'Thank you for donating',
        donate_d:'Our generous act of giving resonates like a ripple through the fabric of humanity, inspiring compassion and kindness in the hearts of others.',
        and:' and '
      }
    },
    fn: {
      translation: {
        Hi: "Tervetuloa Beaconryyn",
        Actions: "Koulutukseen sijoittaminen on investointi ihmiskunnan suurimpaan potentiaaliin.  ",
        wellcoming: "Liity hyväntekeväisyysjärjestöömme, kun rakennamme kouluja, inspiroimme ja muutamme elämää.",
        Education: "Koulutus on avain, joka avaa mahdollisuuksien ovet.",
        Together: "Rakennetaan yhdessä kouluja ja annetaan oppilaille mahdollisuus luoda valoisampi tulevaisuus.",
        Donate: "Lahjoita",
        time1: "Vuodesta",
        time_no:  "2005 lähtien",
        aboutAbove: "MEISTÄ",
        About: "Tervetuloa Beacoryyn, voittoa tavoittelemattomaan organisaatioon, joka on sitoutunut rakentamaan kouluja ja tarjoamaan koulutusta maailmanlaajuisesti. Missiomme on vahvistaa heikommassa asemassa olevia yhteisöjä luomalla kestäviä koulutusmahdollisuuksia, vaalimalla nuorten kehitystä, tukemalla vanhuksia ja edistämällä positiivista muutosta.",
        About2:'Perustamisestamme vuonna 2003 G.C. lähtien olemme työskennelleet aktiivisesti Etiopian koulutuskuilujen kuromiseksi. Intohimoisten henkilöiden tiimimme uskoo, että koulutus on avain köyhyyden kierteen katkaisemiseen ja jokaisen lapsen todellisen potentiaalin vapauttamiseen. Meitä ohjaa uskomus, että jokainen lapsi ansaitsee pääsyn laadukkaaseen koulutukseen sosiaalis-taloudellisesta taustastaan ​​riippumatta.',
        wwd:'Mitä me teemme',

        bs:'Koulujen rakentaminen',
        bs_d:'Ymmärrämme infrastruktuurin keskeisen roolin koulutuksen edistämisessä. Teemme yhteistyötä paikallisten yhteisöjen kanssa rakentaaksemme kouluja alueille, joilla koulutusresurssit ovat niukat.',
        es:'Koulutustuki',
        es_d:'Uskomme, että koulutus ylittää luokkahuoneen. Organisaatiomme on sitoutunut tarjoamaan kattavaa tukea opiskelijoille ja varmistamaan, että heillä on resurssit, joita he tarvitsevat menestyäkseen akateemisesti.',
        ce:'Yhteisön sitoumus',
        ce_d:'Uskomme vahvasti yhteisön osallistumisen ja yhteistyön voimaan. Teemme tiivistä yhteistyötä paikallisten yhteisöjen, vanhempien ja sidosryhmien kanssa ymmärtääksemme heidän tarpeitaan ja toiveitaan. ',
        sd:'Kestävä kehitys',
        sdd:'Beaconry on sitoutunut pitkän aikavälin kestäviin ratkaisuihin. Korostamme ympäristötietoisuuden merkitystä ja edistämme ympäristöystävällisiä käytäntöjä koulun rakentamisessa ja toiminnassa.        ',
        els:'Vanhusten tuki',
        elsd:'Hyväntekeväisyysjärjestömme on omistautunut tarjoamaan kattavaa tukea ja hoitoa paikkakunnallamme oleville vanhuksille. Uskomme, että jokainen eläkeläinen ansaitsee elää täyttävää ja ihmisarvoista elämää kultaisina vuosinaan. Erilaisten ohjelmien ja palveluiden avulla pyrimme vastaamaan heidän fyysisiin, emotionaalisiin ja sosiaalisiin tarpeisiin.',
       
       
       dtos:'Lahjoita koulullemme',
       
        st:'opiskelija',
        kefle:'Kefle on nuori mies, joka tarvitsee parempaa koulutusta. Hän työskentelee vartijana BEACONRY SCHOOL PROJECT: ssä minimipalkalla itsensä ja perheensä elättämiseksi. Hän ansaitsee paremman koulutuksen ja paremman tulevaisuuden. Ole hyvä ja lahjoita nuorten aikuisten koulutukseen.',
        Beru: "Beru Getachew on nuori mies, jolla on yliopistokoulutus ja maisterintutkinto opetuksessa. Hän työskentelee Lighthouse koulussa kouluohjaajana pienellä palkalla. Hän on tuleva opettajamme opettajamme opettajien koulutuksessa BEACON OPETTAJIEN KOULUSSA. Lahjoita uuden koululle perustamiseen ja merkitse nimesi BEACONRY OPETTAJAN KOULUUN. Lahjoitta tästä, kiitos!",
        Gudaye: "Gudaye ja Tigist ovat lastentarhanopettajia, jotka työskentelevät pienellä palkalla tukeakseen koulua ja yhteisöä. He tarvitsevat lisäkoulutusta, ja heidät rekrytoidaan ensimmäisiksi opiskelijoiksi BEACONRY OPETTAJIEN KOULUUN. Lahjoita tulevien opettajien koulutukseen ja palkkaan. ",
        name: "Guday Beyene  ",
        Teacher: "Paiväkoti opettaja",
        Below: "Opettaja",
        
        paragraphFi: "Hailu on hallinoija, joka tarvitsee koulutusta koulun johtajuuteen, tietokonetaitoihin ja kielten opiskeluun.  Koulutuksen tarjoaminen aikuistyöntekijöille ja harjoittelijoille BEACONRY KOULU PROJEKTISSA tukee koko yhteisöä. Ihmiset, kuten Hailu, tietävät tietotaidon kulttuurista, ihmisistä ja yhteisöstä, mutta he tarvitsevat lisää koulutusta, erityisesti tietokonetaitoihin. Kun lahjoitat koulutusprojekteihimme, parannat työvoiman osaamista. Lisäksi tuet heidän perheitään ja sukujaan.",
        titleProject: "BEACONRY KIRJASTO PROJEKTI",
        project: "Kirjastoprojekti on osa kouluprojektia.Lahjoita kirjastoprojektillemme koulun.Koulurakennuksessa. Lahjoituksesi tuloja.Kiitos tuestanne.",
        gallery: "Galleria",
        contact: "Ota YhteyttÄ ",
        workWithUs: "Työskentele kanssamme, yhdessä voimme vaikuttaa.",
        join: "Liity rakentamaan kirkasta tulevaisuutta kanssamme, yksi elämä kerrallaan. Yhdessä voimme tehdä pysyvän vaikutuksen. Vahvistaa yhteisöjä ja muuttaa elämää. Tukesi voi luoda mahdollisuuksien maailman. ",
        testimonial: "TODISTUKSIA",
        messages: "Viestejä lahjoittajilta",
        num1: "+358",
        individual: "Autettuja henkilöitä",
       
        do_name:'Lahjoittajan nimi',
        endProject: "Projektit on tehty ",
        founder:"PERUSTAJA JA   JOHTAJA",
        rahel: "Rahel Tariku",
        paragraph1: " Rahel Tariku syntyi ja kasvoi Etiopiassa. Hän on luontaisesti lahjakas opettaja, jolla on opettajantutkinto Alpha University College Addis Abebasta; ETIOPIASSA ja kasvatustieteiden maisterin tutkinto JYVÄSKYLÄN YLIOPISTOsta. Hänen omistautumisensa ja rakkautensa pienten lasten tukemiseen ympäri maailmaa alkoi vuonna 2005 perustamalla Lighthouse School. Yli 17 vuoden kokemus käytännöllisestä opettamisesta, koulun perustamisesta ja koulujärjestöjen ymmärtämisestä Etiopiassa, Suomessa ja muualla rikastutti hänen ymmärrystään kestävistä ja osallistavista kouluista. ",
        
        paragraph2: "Koulun perustaminen tarvitsee sydämesi ei vain tietoa. Eläen Suomessa, jossa on nelja vuodenaikaa ja rento elämäntapa, hänen visionsa ja missionsa on edelleen ajatella lapsia, jotka tarvitsevat koulua, ruokaa ja majoitsta. Vanhuksia, jotka tarvitsevat kokonaisvaltaisia palveluita. Suuria perheitä, jotka tarvitsevat koulutusta, parempaa elintasoa. Kirjastoa, joka antaa yhteisölle elämää. ",
        paragraph3: "Nyt hänellä on parempi koulutus ja enemmän tietoa.päällä. Tilaa koulutustaitoni, kaiken tasoiset opettaien kouluukset (kurssin nimi on opettajan laukku) luokkahuonepagogiikka,rehtorikoulutus,koulun hallinto, opettaja-oppilassuhde,lyhyt-ja pitkäkestoinen koulutus tai kesäkoulutus. Verkko - tai henkilökohtaiset opetukset odottavat sinua. Tilaamalla hänen asiantuntijuuttaan, autat hänen visiotaan auttaa nyt ja tulevissa projekteissa ympäri maailmaa. Beaconry (Majakka) toimii ihmisille ihmisten kanssa. ",
        beaconrytitle: "Beaconry senioritaloprojekti ",
        beaconryProject: " Vuosi sitten äitini Elizabeth Abebe soitti minulle kävelyvaikeuksistaan ja siitä, millaista tukea hän tarvitsi.  Olin järkyttynyt ja erittäin surullinen kuullessani, että hän ei voi kävellä tai liikkua aktiivisesti kuten ennen ja että minulla oli rajoitteita voidakseni tukea häntä. Päätin löytää kestävän ratkaisun kaikille äideille. Sen sijaan että auttaisin vain äitiäni, visioin BEACONRY ELISABETH HOME ETHIOPIA:n, perustettavaksi äidilleni ja kaikille äideille, jotka enimmäkseen osaavat tehdä kovasti töitä ja antaa.",
        
        beaconryProject_2: "Elisabeth antoi paljon yhteisölle ja perheelleen. Äitini on roolimalli ja äiti monille nuorille. Monet äidit ovat väsyneitä, joka liittyy heidän ikäänsä, tasapainoisen ruokavalion puutteeseen, urheiluhallitoimintaan alueellaan, valmentajien puutteeseen, sotaan, köyhyyteen, ikään liittyvien sairauksien väärinymmärrykseen ja ikääntymiseen valmistautumisen puutteeseen. Tällainen elintapa voi olla musertavaa ja erittäin surullista.",
        
        beaconryProject_3: "Monet vanhukset kärsivät samanlaisista tilanteista. Työskennelkäämme YHDESSÄ BEACONRY ELIZABETH HOME ETHIOPIA:n perustamiseksi. Tekemällä niin, pelastat äitini ja pelastat monia äitejä Etiopiassa. BEACONRY ELISABETH HOME ETHIOPIA rakennetaan sinne, missä luonto ja sääolosuhteet ovat suotuisat.Päiväkoti. tori, maatila,liikuntakeskus,harrastushuneet ovat helposti lähellä heitä.  Sinne kokoontuvat vanhukset voivat hankkia tuloja BEACONRY-projektin jatkokehitykseen.  Miten? Ota yhteyttä meihin! Lahjoita tänään äitini, muiden äitien ja vanhusten elämän puolesta. Kiitos!",
        
        beaconryProject_4: "BEACONRY ELSABETH HOME ETHIOPIA perustetaan lahjoituksillasi ja testamenteillasi Rahallinen tukest on tervetullut.Tarvitsemme tilat, jotka ovat helppoja vanhuksille ja lapsille. Lääkäreiden, terveydenhuollon työntekijöiden, urheiluvalmentajien ja vanhusten haasteiden asiantuntijoiden odotetaan jakavan tietojaan Etiopian kauniilla esikaupunki- ja maaseutualueella.",
        question: " Jos sinulla on kysyttävää, Ota meihin yhteyttä ",
        yourName: "Nimi ",
        email: "Sähköposti osoite",
        mobile: "Puhelin numero",
        subject: "Aihe ",
        message: "Viesti",
        home: "Koti ",
        aboutUs: "Meistä ",
        stories: "Tarinoita",
        contactUs: "Ota meihin yhteyttä ",
        donate2: "Lahjoita",

        address:'Osoite',
        news_letter:'Uutiskirje',
        signUp:'Kirjaudu',
        send:'Lähettää',
        designed_by:'Suunnitellut',
        arr:'Kaikki oikeudet pidätetään',
        elderly_home:"Vanhusten koti",
        charity_1:'Hyväntekeväisyysjärjestömme on omistautunut tarjoamaan kattavaa tukea ja hoitoa paikkakunnamme vanhuksille. Uskomme, että jokainen eläkeläinen ansaitsee elää täyttävää ja ihmisarvoista elämää kultaisina vuosinaan. Erilaisten ohjelmien ja palveluiden avulla pyrimme vastaamaan heidän fyysisiin, emotionaalisiin ja sosiaalisiin tarpeisiin. Vanhusten tukialoitteemme kattavat monenlaisia ​​toimintoja, mukaan lukien terveydenhuollon apu, kotikäynnit ja seuraohjelmat eristäytymisen torjumiseksi. Järjestämme myös virkistystapahtumia, työpajoja ja koulutustilaisuuksia pitääksemme heidän mielensä aktiivisena ja aktiivisena. Lisäksi teemme yhteistyötä terveydenhuollon ammattilaisten ja vapaaehtoisten kanssa varmistaaksemme säännölliset terveystarkastukset, lääkityksen hallinnan ja erikoishoidon niille, joilla on tiettyjä sairauksia.',
        charity_2:'Korostamme hyväntekeväisyydessämme, kuinka tärkeää on edistää yhteenkuuluvuuden tunnetta ja tarkoituksenmukaisuutta vanhusten yhteisössämme. Pyrimme ponnisteluillamme luomaan osallistavan ja välittävän ympäristön, jossa he voivat löytää iloa, tukea ja lohtua tiedosta, että heitä vaalitaan ja kunnioitetaan. Yhdessä pyrimme tekemään heidän myöhemmistä vuosistaan ​​todella kultaisia.',
        language:'KIELI',
        test_1:' Olen erittäin iloinen voidessani todeta, että kokemukseni yhteistyöstä BEACONRY Organizationin kanssa on ollut erinomainen ja koen sen myös etuoikeudeksi. Arvostan sitä erinomaista työtä, jota BEACONRY tekee vähäosaisten lasten kohottamiseksi. Tarjoamalla mahdollisuuksia tarvitseville lapsille BEACONRY investoi maailman parempaan tulevaisuuteen, koska lapset ovat tulevaisuus.',
        test_2:'Minusta on valtava ilo jakaa ajatukseni BEACONRY-organisaatiosta. Ajatus realistisesta kansalaisjärjestölakosta tuli mieleeni, kun törmäsin vähäosaisiin lapsiin, jotka jäivät ilman perustarpeita, kuten koulutusta, terveydenhuoltoa ja ravintoa.',
        admin:'Järjestelmänvalvoja',
        th_donate:'Kiitos lahjoituksestasi',
        donate_d:'Antelias antamisen tekomme resonoi kuin aaltoilu ihmiskunnan kudoksen läpi ja inspiroi myötätuntoa ja ystävällisyyttä muiden sydämiin.',
        and:' ja '
      }
    },
  },
  keySeparator: false,
  interpolation: { escapeValue: false }
});

export default i18n;
